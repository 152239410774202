import React from 'react';
import { Box, Grid } from '@mui/material';
import ProjectCard from '../ProjectCard';
import heart_disease_detection from './images/heart_disease_detection.png';
import task_timer from './images/task_timer.png';
import forum_thumbnail from './images/django_forum.png';
import BTC_CAD_thumbnail from './images/btc_cad_converter.png';
import EAS_thumbnail from './images/etch_a_sketch.png';
import array_thumbnail from './images/array_game.png';
import RPS_thumbnail from './images/rock_paper_scissors.png';
import landing_page_thumbnail from './images/landing_page.png';
import function_interpreter from './images/function_interpreter.png';
import attraction_advisor from './images/attraction_advisor.png';

export const Projects = () => {
  return (
    <Box
      sx={{
        margin: 5,
      }}
    >
      <Grid container spacing={5} justifyContent={'center'}>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={function_interpreter}
            title="LLM CodeMentor"
            stack={[
              'React',
              'Express',
              'SQLite',
              'TypeScript',
              'GPT-4o',
              'Docker',
              'Figma',
            ]}
            body={`Utilizing OpenAI's large language model GPT-4o, users plain english interpretations \
            of functions are transformed into code and tested for functional equivalence. \n
            After entering their interpretation of the code and pressing submit, the llm generated code will be displayed \
            and the number of test cases passed will be shown. \n 
            This application features a RESTful backend implemented in express.js. The backend handles user \
            authentication, OpenAI api calls, question fetching, function testing. \n
            The user interface was mocked in Figma and implemented in React with Vite. \n
            For convenience, please use these credentials {user:test password:test}.`}
            testLink="http://function-interpreter.cooperwebb.xyz"
            codeLink="https://github.com/CCWebb14/LLM-CodeMentor"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={attraction_advisor}
            title="Attraction Advisor"
            stack={['SQL', 'Oracle DBMS', 'JavaScript', 'Express']}
            body={`This project resides in the domain of tourism in Canada. \
            The application is applicable for tourists that want to visit areas that best fit their preference criteria \
            (achieved with activity category filtering), and add reviews and comments to help future tourists to make informed decisions. \n
            The database models all relevant information about a tourist attraction, \
            such as available activities, booking information, photos, and it's location. \n
            The frontend was developed with HTML5/CSS3 along with Bootstrap components. The frontend \
            interacts with the Express backend which subsequently executes SQL queries utilizing Oracles Database management system.`}
            codeLink="https://github.com/CCWebb14/Attraction-Advisor"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={task_timer}
            title="Task Timer"
            stack={['Java', 'Swing', 'JUnit']}
            body="This is a pomodoro timer application developed in Java with the Swing GUI library. It lets you load and save data
             from a local JSON file. The timer can be paused or cancelled at any time and daily
             statistics will be recorded"
            testLink="https://github.com/CCWebb14/Task_Timer/releases/tag/1.0.0"
            codeLink="https://github.com/CCWebb14/Task_Timer"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={heart_disease_detection}
            title="Heart Disease Detection Model"
            stack={['Python', 'scikit-learn', 'Pandas', 'Vega-Altair']}
            body="Using python and libraries such as pandas, sckikit-learn and altair our group was 
            able to build a heart disease detection model using historical data. 
            Utilizing the a dataset from UC Irvine's Machine Learning Repository, 
            we meticulously analyzed data from 303 patients who underwent coronary angiography at the 
            Cleveland Clinic from May 1981 to September 1984. Our approach harnessed the k-nearest neighbors 
            classifier, renowned for its simplicity and accuracy, particularly in medical diagnostics.
            "
            testLink="https://ccwebb14.github.io/DSCI100_Group_Project/"
            codeLink="https://github.com/CCWebb14/DSCI100_Group_Project"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={forum_thumbnail}
            title="Django-React Forum"
            stack={['React', 'Django-REST', 'SQLite', 'Javascript']}
            body="In this project, the Django-REST framework was used
             to generate a backend with multiple API endpoints for user registration,
             sign-in, authentication, logout, posting, commenting and more.
             React and axios on the frontend are used to interact with the
             endpoints. Simply click on a post to generate a detailed view with comments.
             When a user is signed in they can create posts or reply to others by clicking
             on a comment. This service is run on an AWS EC2 server with an SQLite database. This project
             is still very early in development. Please use these credentials {user:DemoUser password:mpDP6E6idvonjVI3}"
            testLink="http://forum.cooperwebb.xyz"
            codeLink="https://github.com/CCWebb14/django-react-forum"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={BTC_CAD_thumbnail}
            title="Bitcoin to CAD converter"
            stack={['JavaScript', 'CSS/HTML']}
            body="A bitcoin to Canadian dollar converter built using html, css and javascript.
             Enter the quantity you would like to convert
             and the output will be displayed above. This application functions by
             fetching bitcoins current price from coingeckos api."
            testLink="https://ccwebb14.github.io/bitcoin-cad-converter/"
            codeLink="https://github.com/CCWebb14/bitcoin-cad-converter"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={EAS_thumbnail}
            title="Etch-a-Sketch"
            stack={['JavaScript', 'CSS/HTML']}
            body="An etch-a-sketch board built with html, css and javascript.
             Simply enter a square number for the size of board you would like to draw on. 
             Click and drag on the canvas to get started. Built for the Odin Projects curriculum."
            testLink="https://ccwebb14.github.io/Etch-a-Sketch/"
            codeLink="https://github.com/CCWebb14/Etch-a-Sketch"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={array_thumbnail}
            title="Array Game"
            stack={['JavaScript', 'CSS/HTML']}
            body="An html, css and javascript game where the objective is to turn
             all of the lights on. The buttons on the left and top toggle all of the lights
             in its respective row, column or in a diagonal fashion.
             The buttons on the right will invert a horizontal sequence."
            testLink="https://ccwebb14.github.io/Array-Light-Game/"
            codeLink="https://github.com/CCWebb14/Array-Light-Game"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={RPS_thumbnail}
            title="Rock-Paper-Scissors"
            stack={['JavaScript', 'CSS/HTML']}
            body="A simple html, css and javascript game of Rock Paper Scissors.
             Select your weapon of choice at the top and an output will be displayed below.
             First to 5 rounds wins! Built for the Odin Projects curriculum."
            testLink="https://ccwebb14.github.io/Rock-Paper-Scissors/"
            codeLink="https://github.com/CCWebb14/Rock-Paper-Scissors"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ProjectCard
            imageLink={landing_page_thumbnail}
            title="Landing Page"
            stack={['CSS/HTML']}
            body="A mockup html and css webpage. This was built to better understand
             css properties such as flexbox. 
             Built for the Odin Projects curriculum."
            testLink="https://ccwebb14.github.io/Landing-Page/"
            codeLink="https://github.com/CCWebb14/Landing-Page/tree/plain"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Projects;
